// Customizable Area Start
import React from "react";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import { Box, Button, Container, CssBaseline, TextField, Typography } from "@mui/material";
import PhoneVerificationController, { Props, configJSON } from "./PhoneVerificationController.web";
import ContentWrapper from "./ContentWrapper.web";
import OtpInput from "react-otp-input";
import assets from "../assets";
import { Form, Formik } from "formik";
import * as Yup from "yup";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2EAB65",
    },
    secondary: {
      main: "#4768BE",
    },
    text: {
      primary: "#14101E",
    },
  },
  typography: {
    fontFamily: '"Manrope", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: "26px",
      lineHeight: 1.25,
      fontWeight: 800,
      color: "#000",
      "@media (max-width: 600px)": {
        fontSize: "20px",
      },
    },
    subtitle2: {
      lineHeight: 1.5,
      fontSize: "24px",
      "@media (max-width: 600px)": {
        fontSize: "20px",
      },
    },
    body1: {
      fontSize: "14px",
      color: "#000",
      fontWeight: 800,
      "@media (max-width: 600px)": {
        fontSize: "12px",
      },
    },
    body2: {
      fontSize: "16px",
      "@media (max-width: 600px)": {
        fontSize: "12px",
      },
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          fontSize: "14px",
          textTransform: "none",
          fontWeight: "800",
          boxShadow: "none",
          padding: "10px 14px",
          lineHeight: "1.4",
          "&:hover": {
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          },
          "@media (max-width: 600px)": {
            fontSize: "12px",
          },
        },
        contained: {
          color: "#FFF",
          "&:disabled": {
            backgroundColor: "#B6D4E3",
            color: "#fff",
          },
        },
        text: {
          "&:hover": {
            boxShadow: "none",
          },
        },
      },
    },
  },
});
// Customizable Area End

export default class PhoneVerification extends PhoneVerificationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <ContentWrapper navigation={this.props.navigation}>
          <BoxWrapper>
            <Container className="main__content__choose__role" maxWidth="sm">
              <img src={assets.varification} alt="icon" />

              <Typography variant="h1" sx={{ mt: 2, mb: 0.75 }} data-test-id="title">
                {configJSON.verifyPhoneLabel}
              </Typography>
              <Typography variant="body2">{configJSON.verifyPhoneDesc}</Typography>

              <Formik
                data-test-id="formik-wrapper"
                initialValues={{ pin: "" }}
                validationSchema={Yup.object({
                  pin: Yup.string()
                    .length(5, "Code is not correct, please try one more time")
                    .matches(/^\d{5}$/, "Code is not correct, please try one more time")
                    .required("Required"),
                })}
                onSubmit={this.handleSubmit}
              >
                {({ values, setFieldValue, touched, errors, isValid, dirty, isSubmitting }) => (
                  <Form className="form__wrapper">
                    <Box className="otp__wrapper" sx={{ mt: { xs: 2, sm: 3 } }}>
                      <OtpInput
                        data-test-id="otp-wrapper"
                        value={values.pin}
                        onChange={(pin) => setFieldValue("pin", pin)}
                        numInputs={5}
                        shouldAutoFocus
                        inputStyle={{
                          flex: 1,
                        }}
                        containerStyle={{
                          gap: "16px",
                        }}
                        renderInput={(props) => <input {...props} className={`otp__box ${touched.pin && errors.pin ? "error" : ""}`} />}
                      />
                    </Box>

                    {touched.pin && errors.pin && (
                      <Typography className="error__message" sx={{ mb: 3, mt: 0.5 }}>
                        {errors.pin}
                      </Typography>
                    )}

                    <Button type="submit" disabled={isSubmitting || !dirty || !isValid} fullWidth variant="contained" sx={{ mb: 2, mt: 4 }}>
                      {configJSON.btnVerify}
                    </Button>
                    <Button data-test-id="resend-btn" onClick={this.resendOtp} fullWidth variant="text">
                      {configJSON.btnResendCode}
                    </Button>
                  </Form>
                )}
              </Formik>
            </Container>
          </BoxWrapper>
        </ContentWrapper>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const BoxWrapper = styled(Box)({
  flex: 1,
  "& .main__content__choose__role": {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
    maxWidth: "480px",
    "& .form__wrapper": {
      width: "100%",
      "& .otp__wrapper": {
        "& .otp__box": {
          aspectRatio: 1.15,
          borderRadius: "8px",
          fontSize: "16px",
          fontWeight: 500,
          border: "solid 2px #E2E8F0",
          "&.error": {
            borderColor: "red"
          },
          "&:focus-visible": {
            border: "solid 2px #2EAB65",
            outline: "none",
          },
          "& .MuiInputBase-input": {
            textAlign: "center",
          },
        },
      },
      "& .error__message": {
        color: "#DC2626",
        fontSize: "12px",
        lineHeight: "16px",
        fontWeight: 400,
      },
    },
  },
});
// Customizable Area End
