// Customizable Area Start
import React from "react";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import { Box, Button, Container, CssBaseline, Typography } from "@mui/material";
import SignUpChooseRoleController, { Props, configJSON } from "./SignUpChooseRoleController.web";
import ContentWrapper from "./ContentWrapper.web";
import assets from "../assets";
import { HealthCareType } from "./SignUpChooseRoleController.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2EAB65",
    },
    secondary: {
      main: "#4768BE",
    },
    text: {
      primary: "#14101E",
    },
  },
  typography: {
    fontFamily: '"Manrope", "Roboto", "Helvetica", "Arial", sans-serif',
    subtitle1: {
      fontSize: "24px",
      lineHeight: 1.25,
      fontWeight: 800,
      color: "#000",
      "@media (max-width: 600px)": {
        fontSize: "20px",
      },
    },
    subtitle2: {
      lineHeight: 1.25,
      fontSize: "24px",
      "@media (max-width: 600px)": {
        fontSize: "20px",
      },
    },
    body1: {
      fontSize: "14px",
      color: "#000",
      fontWeight: 800,
      "@media (max-width: 600px)": {
        fontSize: "12px",
      },
    },
    body2: {
      fontSize: "14px",
      "@media (max-width: 600px)": {
        fontSize: "12px",
      },
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          fontSize: "14px",
          textTransform: "none",
          fontWeight: "700",
          boxShadow: "none",
          padding: "10px 18px",
          maxWidth: "453px",
          lineHeight: "1.4",
          "&:hover": {
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          },
          "@media (max-width: 600px)": {
            fontSize: "12px",
          },
        },
        outlined: {
          color: "#2EAB65",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          gap: "16px",
          padding: "24px 48px",
          boderRadius: "14px",
          "@media (max-width: 600px)": {
            gap: "12px",
            padding: "18px 36px",
          },
        },
      },
    },
  },
});
// Customizable Area End

export default class SignUpChooseRole extends SignUpChooseRoleController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <ContentWrapper navigation={this.props.navigation}>
          <BoxWrapper>
            <Container className="main__content__choose__role">
              <Typography
                variant="subtitle1"
                align="center"
                sx={{ mb: { xs: 4, sm: 7 } }}
                data-test-id="choose-role-page-title"
                dangerouslySetInnerHTML={{ __html: configJSON.chooseRolePageTitle }}
              ></Typography>

              <Box className="btn__wrapper">
                <Button data-test-id="btn-facility" fullWidth variant="outlined" onClick={() => this.selectRole(HealthCareType.Facility)} sx={{ aspectRatio: 1 }}>
                  <img className="btn__icon" src={assets.iconFacility} alt="icon" />
                  {configJSON.btnFacilityLabel}
                </Button>
                <Button data-test-id="btn-professional" fullWidth variant="outlined" onClick={() => this.selectRole(HealthCareType.Professional)}>
                  <img className="btn__icon" src={assets.iconProfessional} alt="icon" />
                  {configJSON.btnProfessionalLabel}
                </Button>
              </Box>
            </Container>
          </BoxWrapper>
        </ContentWrapper>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const BoxWrapper = styled(Box)({
  flex: 1,
  "& .main__content__choose__role": {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
  },
  "& .btn__wrapper": {
    display: "flex",
    gap: "24px",
  },
  "@media (max-width: 400px)": {
    "& .btn__wrapper": {
      "& .btn__icon": {
        width: "40px",
      },
    },
  },
});
// Customizable Area End
