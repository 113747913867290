
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const Rectangle7 = require("../assets/Rectangle7.png");
export const Rectangle8 = require("../assets/Rectangle8.png");
export const logo = require("../assets/logo.png");
export const Rectangle9 = require("../assets/Rectangle8.png");
export const Rectangle10 = require("../assets/Rectangle10.png");
export const Rectangle11 = require("../assets/Rectangle11.png");
export const clock_loader_20 = require("../assets/clock_loader_20.png");

export const iconFacility = require("../assets/image_facility.png");
export const iconProfessional = require("../assets/image_pro.png");

export const group1 = require("../assets/group1.png");
export const group2 = require("../assets/group2.png");
export const group3 = require("../assets/group3.png");
export const group4 = require("../assets/group4.png");
export const group5 = require("../assets/group5.png");

export const eyeOpen = require("../assets/eye_open.png");
export const eyeClosed = require("../assets/eye_closed.png");
export const approved = require("../assets/right.png");
export const varification = require("../assets/verification.png");
export const successGroupPhoto = require("../assets/success_group_photo.png");

const assets = {
    Rectangle7,
    Rectangle8,
    logo,
    imgPasswordVisible,
    imgPasswordInVisible,
    Rectangle9,
    Rectangle10,
    Rectangle11,
    clock_loader_20,
    group1,
    group2,
    group3,
    group4,
    group5,
    iconFacility,
    iconProfessional,
    eyeClosed,
    eyeOpen,
    approved,
    varification,
    successGroupPhoto
  };
  
  export default assets;

