import React, { Component } from "react";
import { Field, FieldProps } from "formik";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Box, styled } from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import assets from "../assets";

type CustomInputProps = TextFieldProps & {
  showEndAdornment?: boolean
}

interface CustomInputState {
  showPassword: boolean;
}

class CustomInput extends Component<CustomInputProps, CustomInputState> {
  constructor(props: CustomInputProps) {
    super(props);
    this.state = { showPassword: false };
  }

  handleTogglePassword = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  getEndAdornment = () => {
    if (this.props.type === "password") {
      return (
        <IconButton
          aria-label="toggle password visibility"
          onClick={this.handleTogglePassword}
          edge="end"
        >
          <img src={this.state.showPassword ? assets.eyeOpen : assets.eyeClosed} alt="icon" />
        </IconButton>
      );
    }
  
    if (this.props.showEndAdornment) {
      return <DoneIcon color="secondary" />;
    }
  
    return null;
  }

  renderTextField = ({ field, meta }: FieldProps) => {
    const { label, type, showEndAdornment = false, ...rest } = this.props;
    const { showPassword } = this.state;

    return (
      <InpuStyleWrapper mb={1}>
        <Typography variant="body1" mb={0.5} className="label">
          {label}
        </Typography>
        <TextField
          {...field}
          {...rest}
          type={type === "password" && showPassword ? "text" : type}
          error={Boolean(meta.touched && meta.error)}
          helperText={meta.touched && meta.error || ""}
          InputProps={{
            ...rest.InputProps,
            endAdornment: this.getEndAdornment(),
          }}
        />
      </InpuStyleWrapper>
    );
  };

  render() {
    const { name } = this.props;
    return <Field name={name}>{this.renderTextField}</Field>;
  }
}

const InpuStyleWrapper = styled(Box)({
  "& .label": {
    fontWeight: 700,
    lineHeight: "20px"
  },

  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    backgroundColor: "#FFF"
  },

  "& .MuiOutlinedInput-root:not(.Mui-focused):not(.Mui-error) .MuiOutlinedInput-notchedOutline": {
    borderColor: "#E2E8F0",
  },

  "& .MuiInputBase-input": {
    padding: "10px 12px 12px",
    fontSize: "16px",       
    lineHeight: 1,     
    color: "#0F172A",
    fontWeight: "400",
  },
  "& .MuiFormHelperText-root": {
    fontWeight: 400,
    fontSize: "12px",      
    lineHeight: "18px",    
    marginLeft: "0",    
    marginTop: "0", 
  } 
})

export default CustomInput;
