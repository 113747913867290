import { Box, styled, Typography } from "@mui/material";
import landingPageAssets from "../../../../../packages/blocks/landingpage/src/assets";
import React from "react";
import assets from "../assets";

const configJSON = require("../config.js");

interface S {
  currentIndex: number;
}

const sliderData = [
  {
    image: assets.group1,
    text: configJSON.carouselDesc1,
  },
  {
    image: assets.group2,
    text: configJSON.carouselDesc2,
  },
  {
    image: assets.group3,
    text: configJSON.carouselDesc3,
  },
  {
    image: assets.group4,
    text: configJSON.carouselDesc4,
  },
  {
    image: assets.group5,
    text: configJSON.carouselDesc5,
  },
];

export default class Carousel extends React.Component<{}, S> {
  transitionDuration: number;
  intervalId: null | ReturnType<typeof setTimeout>;

  constructor(props: {}) {
    super(props);
    this.state = {
      currentIndex: 0,
    };
    this.transitionDuration = 4000;
    this.intervalId = null;
  }

  componentDidMount(): void {
    this.intervalId = setInterval(this.changeImage, this.transitionDuration);
  }

  changeImage = () => {
    this.setState((prevState) => ({
      currentIndex: (prevState.currentIndex + 1) % sliderData.length,
    }));
  };

  componentWillUnmount = () => {
    if (this.intervalId) clearInterval(this.intervalId);
  };

  render(): React.ReactNode {
    const { currentIndex } = this.state;

    return (
      <RightBoxWrapper>
        <Box>
          <img className="logo" src={landingPageAssets.logo} alt="logo" />
        </Box>

<Box className="image__wrapper">
        <img src={sliderData[currentIndex].image} alt={`Slide ${currentIndex}`} className="slider__image" />
</Box>

        <Box className="conic-effect" sx={{ animation: "conic-effect 4s linear infinite"}}></Box>

        <Typography variant="subtitle2" align="center" className="slider__desc" dangerouslySetInnerHTML={{ __html: sliderData[currentIndex].text }}>          
        </Typography>
      </RightBoxWrapper>
    );
  }
}

const RightBoxWrapper = styled(Box)({
  width: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  background: "#D1E1E9",
  "& .logo": {
    width: "102px",
    aspectRatio: "1.59",
  },
  "& .slider__desc": {
    transition: "opacity 1s ease-in-out",
    opacity: 1,
    textAlign: "center",
  },
  "& .image__wrapper": {
    minHeight: "485px",
    "& .slider__image": {
      transition: "opacity 1s ease-in-out",
    },
    "@media (max-height: 800px)": {
      margin: "12px auto",
    },
    margin: "24px auto",
  },
  "& .conic-effect": {
    marginBottom: "36px",
  },
  "@media (max-width: 1200px)": {
    "& .logo": {
      width: "80px",
    },
    "& .image__wrapper": {
      maxWidth: "480px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
});
