Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.welcomeScreenLabel = "Welcome Screen";
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";
exports.errorTitle2 = "Error 2";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";

exports.backToWebsiteLabel = "Back to the website";
exports.registerOrLoginLabel = "Register or Log in";
exports.alreadyHaveAccount = "I already have an account";
exports.loginLabel = "Login";
exports.signupLabel = "Sign Up";
exports.sinupActionTitle = "I’m new to <b>Afya Matoh</b>";

exports.backButtonLabel = "Back";
exports.backButtonLabelWebsite = "Back to the website";
exports.chooseRolePageTitle = "You would like to register<br/>as a healthcare...";
exports.btnProfessionalLabel = "Professional";
exports.btnFacilityLabel = "Facility";

exports.carouselDesc1 = "Quick and easy access to trusted<br/> healthcare providers.";
exports.carouselDesc2 = "Find doctors you can trust,<br/> instantly";
exports.carouselDesc3 = "Your Health,<br/> One Click Away";
exports.carouselDesc4 = "Book Appointments in Seconds.<br/> Anytime, Anywhere";
exports.carouselDesc5 = "Designed to make a healthcare<br/> convenient";

exports.btnContinueLabel = "Continue";
exports.IAgreeWith = "I agree with ";
exports.and = " and ";
exports.termsAndConditions = "Terms and Conditions";
exports.privacyPolicy = "Privacy Policy";

exports.createYourAccount = "Create your account";
exports.facilitySignupLabel = "Facility’s primary contact details";

exports.hasCapital = "Capital letter";
exports.hasSpecial = "Special character";
exports.hasNumber = "Number";
exports.hasMinLength = "Min 8 characters";

exports.verifyPhoneLabel = "Verify your phone number";
exports.verifyPhoneDesc = "Enter the 5-digit code that we sent to your phone number";
exports.btnVerify = "Verify";
exports.btnResendCode = "Resend Code";
exports.otpError = "Code is not correct, please try one more time";

exports.phoneLabel = "Phone number";
exports.wantToLogin = "Would you like to login?";
exports.emailError = "This email is already in use.";

exports.successPageTitle = "Your account<br /> has been successfully created!"
exports.successPageDesc = "To begin your journey, we invite you to <b>set up your profile.</b><br />This process should take about 15 minutes."
exports.successBtnLabel = "Let’s start!";

exports.resendOtpEndPoint = "account_block/accounts/send_otps";
exports.confirmOtpEndPoint = "account_block/accounts/sms_confirmations";

// Customizable Area End
