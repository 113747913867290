// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { runEngine } from "../../../../framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";
import { LocalStorageKeys } from "./SignUpChooseRoleController.web";
import { ConfirmOtpErrorResponse, OtpDataToStore, ResendOtpSuccessResponse } from "./types";
import createRequestMessage from "../../../../../packages/blocks/utilities/src/create-request-message";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { FormikHelpers } from "formik";

interface OtpFormValues {
  pin: string;
}

// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string,
  phoneNumber: string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class PhoneVerificationController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  resendOtpApiCall: string = "";
  confirmOtpCallId: string = "";
  setErrorsRef: FormikHelpers<OtpFormValues>['setErrors'] | null = null;
  setSubmittingRef: FormikHelpers<OtpFormValues>['setSubmitting'] | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      phoneNumber: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if(responseJson && !responseJson?.errors) {
      if(apiRequestCallId === this.resendOtpApiCall) {
        if (this.setErrorsRef) this.setErrorsRef({ pin: "" });
        this.setState({ token: (responseJson as ResendOtpSuccessResponse).meta.token});
      }
      if(apiRequestCallId === this.confirmOtpCallId) {
        this.props.navigation.navigate("SignUpSuccess")
      }
    }
    if(responseJson && responseJson?.errors) {
      if(apiRequestCallId === this.confirmOtpCallId) {
        if (this.setErrorsRef) this.setErrorsRef((responseJson as ConfirmOtpErrorResponse).errors[0]);
        if (this.setSubmittingRef) this.setSubmittingRef(false);
      }
    }

    
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {

    const data = (await getStorageData(LocalStorageKeys.OTPVerificationToken, true) as OtpDataToStore)
    this.setState({ token: data.token, phoneNumber: data.phoneNumber });

  }

  handleSubmit = (values: OtpFormValues, { setErrors, setSubmitting }: FormikHelpers<OtpFormValues>) => {

    this.setErrorsRef = setErrors;
    this.setSubmittingRef = setSubmitting;

    const payload = {
      "pin": values.pin
    }

    const requestMessage  = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.confirmOtpCallId = requestMessage.messageId;

    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: configJSON.confirmOtpEndPoint,
      token: this.state.token,
      method: "POST",
      body: JSON.stringify(payload),
    });
    
  }

  resendOtp = () => {
    const payload = {
        "data": {
          "type": "sms_otps",
          "attributes": {
            "full_phone_number": this.state.phoneNumber
          }
        }
    }

    const requestMessage  = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.resendOtpApiCall = requestMessage.messageId;

    createRequestMessage({
      requestMessage: requestMessage,
      endPoint: configJSON.resendOtpEndPoint,
      method: "POST",
      body: JSON.stringify(payload),
    });
  }
  // Customizable Area End
}
