import { Box, Container, Hidden, styled, Typography } from "@mui/material";
import React, { ReactNode } from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Carousel from "./Carousel.web";

const configJSON = require("../config.js");

interface Props {
    navigation: any
    children: ReactNode
    backButtonLabel?: string
}

interface S {
  
}

export default class ContentWrapper extends React.Component<Props, S> {
  render(): React.ReactNode {
    return (
      <>
        <BoxWrapper>
          <Box className="left__wrapper" sx={{ pt: { xs: 2, md: 4 }, pb: { xs: 2, md: 4 } }}>
            <Container>
              <Box className="back__navigation" sx={{ mb: { xs: 1, md: 2 } }} onClick={this.props.navigation.goBack}>
                <ArrowBackIcon />
                <Typography variant="body1">{this.props.backButtonLabel || configJSON.backButtonLabel}</Typography>
              </Box>
            </Container>

            {this.props.children}
          </Box>

          <Hidden mdDown>
            <Carousel data-test-id="carousel" />
          </Hidden>
        </BoxWrapper>
      </>
    );
  }
}

const BoxWrapper = styled(Box)({
  display: "flex",
  "& .left__wrapper": {
    flex: 1,
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    background: "#f8f8f8",
    "& .back__navigation": {
      alignItems: "center",
      display: "inline-flex",
      cursor: "pointer",
      gap: "12px",
    },
  },
  "& .right__wrapper": {
    flex: 1,
  },
});


